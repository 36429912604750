<template>
  <div>
    <v-card class="text-center">
      <v-card-title>Application-Setup</v-card-title>
      <v-card-text>
        Diese Anwendung durchläuft einen kurzen Installationsprozess. Sie werden
        automatisch weitergeleitet.
        <p class="mt-5">
          {{ apiStateMessage }}
          <i
            v-if="apiState !== null"
            class="ml-3"
            :class="apiConnectionIconClass"
          />
        </p>
        <v-btn
          v-if="apiState !== null && !isConnectedToApi"
          @click="startApplicationSetup"
        >
          Erneut versuchen
        </v-btn>
        <div class="w-100">
          <span class="">
            {{ loadingStateMessage }}
            <i
              v-if="loadingState === states.LOADING_FINISHED"
              class="ml-3 fas fa-check text-success"
            />
          </span>
        </div>
        <!--        <div v-if="isConnectedToApi" class="mt-15 mb-10">-->
        <!--          <loading-progress-bar-->
        <!--            :current-progress="loadedAddressesCount"-->
        <!--            :target="maxAddressesCount"-->
        <!--            :finished="loadingState > states.LOADING_ADDRESSES"-->
        <!--          />-->
        <!--        </div>-->
        <!--        <div-->
        <!--          v-if="isConnectedToApi && loadingState > states.LOADING_ADDRESSES"-->
        <!--          class="mt-15 mb-10"-->
        <!--        >-->
        <!--          <loading-progress-bar-->
        <!--            :current-progress="loadedAddressProductsCount"-->
        <!--            :target="maxAddressProductsCount"-->
        <!--            :finished="loadingState > states.LOADING_PRODUCT_STATUS"-->
        <!--          />-->
        <!--        </div>-->
        <div
          v-show="isConnectedToApi && loadingState === states.LOADING_FINISHED"
          class="mt-15 mb-10"
        >
          <timer-countdown
            ref="timer"
            :seconds="5"
            message="Sie werden automatisch weitergeleitet..."
            @finished="pushToNextPage"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import TimerCountdown from "../components/TimerCountdown";
import { LOGOUT } from "@/store/auth.module";

const LOADING_ADDRESSES = 0;
const LOADING_PRODUCT_STATUS = 1;
const LOADING_FINISHED = 2;

export default {
  name: "ApplicationSetup",
  components: { TimerCountdown },
  data() {
    return {
      apiState: null,
      loadingState: "",
      errorState: null,
      states: {
        LOADING_ADDRESSES,
        LOADING_PRODUCT_STATUS,
        LOADING_FINISHED,
      },
    };
  },
  computed: {
    apiStateMessage() {
      if (this.apiState === null) {
        return "Verbindung zum Adress-Service wird aufgebaut...";
      }

      switch (this.apiState.status) {
        case 200:
          return "Verbindung zum Adress-Service ist aktiv.";
        case 401:
          return "Sie sind nicht angemeldet.";
        default:
          return "Verbindung zum Adress-Service ist fehlerhaft.";
      }
    },
    isConnectedToApi() {
      if (this.apiState === null) {
        return false;
      }

      switch (this.apiState.status) {
        case 200:
          return true;
        default:
          return false;
      }
    },
    apiConnectionIconClass() {
      return {
        "fas fa-check text-success": this.isConnectedToApi,
        "fas fa-times text-danger": !this.isConnectedToApi,
      };
    },
    loadedAddressesCount() {
      return this.$store.getters.loadedAddressesCount;
    },
    maxAddressesCount() {
      return this.$store.getters.maxAddressesCount;
    },
    loadedAddressProductsCount() {
      return this.$store.getters.loadedAddressProductsCount;
    },
    maxAddressProductsCount() {
      return this.$store.getters.maxAddressProductsCount;
    },
    loadingAddressPercent() {
      if (
        this.loadingState > LOADING_ADDRESSES &&
        this.loadedAddressesCount === this.maxAddressesCount
      ) {
        return 100;
      } else {
        return (this.loadedAddressesCount / this.maxAddressesCount) * 100;
      }
    },
    loadingAddressProductsPercent() {
      return (
        (this.loadedAddressProductsCount / this.maxAddressProductsCount) * 100
      );
    },
    loadingStateMessage() {
      switch (this.loadingState) {
        case LOADING_ADDRESSES:
          return "Adressen werden aktualisiert ...";
        case LOADING_PRODUCT_STATUS:
          return "Produktstatus wird aktualisiert ...";
        case LOADING_FINISHED:
          return "Alles aktualisiert.";
        default:
          return "";
      }
    },
  },
  watch: {
    loadedAddressesCount(value) {
      if (value > 0 && value === this.maxAddressesCount) {
        this.loadingState = LOADING_FINISHED;
      }
    },
    loadingState(value) {
      if (value === LOADING_FINISHED) {
        this.$refs["timer"].startCountdown();
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("resetLoadedAddressProductCount");

    await this.startApplicationSetup();
  },
  methods: {
    async startApplicationSetup() {
      this.apiState = await ApiService.get("application-state").catch(() => {
        this.$store.dispatch(LOGOUT);
        this.$router.push({ name: "login" });
      });

      await this.$store.dispatch("deleteAddressStorage");

      this.loadingState = LOADING_FINISHED;
    },
    async loadAddresses() {
      this.loadingState = LOADING_ADDRESSES;
      await this.$store.dispatch("updateAddresses");
    },
    async loadAddressProductStatus() {
      this.loadingState = LOADING_PRODUCT_STATUS;
      await this.$store.dispatch("updateAddressProductStatus");
    },
    pushToNextPage() {
      this.$router.push({ name: "addresses" });
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // access to component instance via `vm`
      await vm.$store.dispatch("checkChangedPassword");
      if (vm.$store.getters.changedPassword) {
        next();
      } else {
        next({ name: "change-password" });
      }
    });
  },
};
</script>

<style lang="scss"></style>
