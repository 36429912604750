<template>
  <div class="text-center d-flex flex-column">
    <div>
      <v-progress-circular
        :width="5"
        :size="100"
        :value="value"
        :rotate="-90"
        color="primary"
      >
        <span class="font-weight-bold text-dark" style="font-size: 1.5rem">
          {{ secondsLeft }}
        </span>
      </v-progress-circular>
    </div>
    <span v-if="message" class="mt-5" style="font-size: 1.2rem">
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: "TimerCountdown",
  props: {
    seconds: {
      type: Number,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      secondsLeft: this.seconds,
      timeout: null,
    };
  },
  computed: {
    value() {
      return (this.secondsLeft / this.seconds) * 100;
    },
  },
  methods: {
    startCountdown() {
      clearTimeout(this.timeout);
      this.secondsLeft = this.seconds;
      this.countdown();
    },
    countdown() {
      this.timeout = setTimeout(() => {
        if (this.secondsLeft > 0) {
          this.secondsLeft = this.secondsLeft - 1;
          this.countdown();
        } else {
          this.finished();
        }
      }, 1000);
    },
    finished() {
      this.$emit("finished");
    },
  },
};
</script>

<style scoped></style>
